<template>
  <div class="header_nav">
    <div class="header_content">
      <div class="header_content_box">
        <div class="logo">
          <img @click="pageHome" :src="'https://www.aafdt.cn'+ state.logo" alt="">
        </div>
        <div class="pageChoose">
          <div @click="goPage('0')" class="chooseItem">
            <p :style="state.chooseTag == '0' ? 'color:#029688' : ''">首页</p>
          </div>
          <div @click="goPage('1')" class="chooseItem">
            <p :style="state.chooseTag == '1' ? 'color:#029688' : ''">需求列表</p>
          </div>
          <div @click="goPage('2')" class="chooseItem">
            <p :style="state.chooseTag == '2' ? 'color:#029688' : ''">发布视频</p>
          </div>
          <div @click="goPage('3')" class="chooseItem">
            <p :style="state.chooseTag == '3' ? 'color:#029688' : ''">个人中心</p>
          </div>
        </div>

        <div class="message">
          <!-- <el-badge value="..." :max="99" class="item">
            <img class="messageImg" src="../assets/mycenter/messageTag.png" alt="">
          </el-badge> -->
        </div>

        <div class="userinfo">
          <img :src="state.userInfo.avatar" alt="">
          <p class="username">
            欢迎您！{{ state.userInfo.name }}
          </p>
          <p class="logout" @click="logout">退出</p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { reactive } from 'vue'

import { useRouter } from 'vue-router';
import http from "@/api/axios.js";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  name: "headerLayout",
  setup() {
    const router = useRouter();
    const state = reactive({
      userInfo: {
        avatar: require('../assets/mycenter/avatarArtisan.png'),
        name: ''
      },
      chooseTag: '0',
      logo: ''
    })

    const logout = () => {
      sessionStorage.clear()
      router.push(`/userLogin`)
    }
    const pageHome = () => {

      router.push(`/homePage`)
    }
    const img = () => {
   
        http.post('api/index/websiteLogo').then(res => {
          if (res.data.code == 200) {
            state.logo = res.data.data
          }

        })
      }
      const goPage = (index) => {

        if (index == 1 || index == 2 || index == 3) {
          var token = sessionStorage.getItem("token")

          http.post("/api/index/userData", {
            token: token
          }).then((res) => {

            if (res.data.data.name == "" || res.data.data.name == null) {
              ElMessage.error("请先完成认证");
              return
            } else {
              if (index == state.chooseTag) {
                return
              }
              switch (index) {
                case '0':
                  state.chooseTag = '0'
                  router.push(`/homePage`)
                  break;
                case '1':
                  state.chooseTag = '1'
                  router.push(`/demandhall`)
                  break;
                case '2':
                  state.chooseTag = '2'
                  router.push(`/Postvideo`)
                  break;
                case '3':
                  state.chooseTag = '3'
                  router.push(`/myCenterArtisan`)
                  break;

                default:
                  break;
              }
            }
          });
        }

        if (index == 0) {
          state.chooseTag = '0'
          router.push(`/homePage`)
        }

      }
      return {
        state,
        logout,
        pageHome,
        goPage,
        img
      }
    },
      mounted(){
        this.img()
        //  console.log("999")
        var token = sessionStorage.getItem("token")
        if (token == null || token == "") {
          this.$router.push("/userLogin");
          return
        }
        http.post("/api/index/userData", {
          token: token
        }).then((res) => {
          if (res.data.code == 200) {
            this.state.userInfo.name = res.data.data.username
          }
          if (res.data.data.type == 1) {
            this.$router.push("/homePage");
          } else {
          }
        });
      }
  }
</script>

<style scoped>
.header_nav {
  position: sticky;
  height: 70px;
  top: 0;
  z-index: 2;
}

.header_nav_p {
  width: 100vw;
  height: 40px;
  background: #F1F3F8;
}

.header_nav_box {
  width: 1200px;
  height: 40px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header_nav_p_right {
  width: 175px;
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  line-height: 19px;
}

.header_nav_p_left {
  width: 129px;
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
  line-height: 19px;
}

.header_content {
  /* width: 1920px; */
  height: 70px;
  background: #FFFFFF;
}

.nav_box {
  width: 1200px;
  margin: 0 auto;
  padding-top: 30px;
  height: 56px;
  display: flex;
}

.header_content_box {
  margin: 0 auto;
  /* padding-top: 10px; */
  width: 1200px;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header_content_box img {
  width: 189px;
  height: 53px;
  /* margin-top: 10px; */
}

.layui-input {
  height: 53px;
}

.layui-btn_serach {
  height: 53px;
  font-size: 16px;
  width: 106px;
  background: #029688;
}

.layui-btn_fabu {
  width: 174px;
  height: 53px;
  border: 2px solid #029688;
}

.fabu_box {
  margin-left: 34px;
}

.search_box {
  width: 474px;
  height: 53px;
  background: #F1F3F8;
  border: 1px solid #E1E1E1;
  display: flex;
  font-size: 16px;
}

.msg_box {
  margin-left: 133px;
  margin-top: 12px;
  position: relative;
}

.msg_box img {
  width: 31px;
  height: 31px;
}

.msg_box p {
  position: absolute;
  width: 19px;
  height: 19px;
  background-color: red;
  border-radius: 50%;
  text-align: center;
  color: white;
  right: -7px;
  top: -7px;
}

.nav_kind {
  width: 238px;
  height: 56px;
  line-height: 56px;
  background: #029688;
  border-radius: 20px 20px 0px 0px;
  text-align: center;
}

.kind_icon img {
  width: 20px;
  height: 19px;
  margin-bottom: 5px;
}

.kind_text {
  margin-left: 9px;
  width: 73px;
  height: 17px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #F1F3F8;
  line-height: 19px;
}

.router_box {
  margin-left: 42px;
}

.title_position {
  margin-top: 15px;
}

.login_box {
  margin-top: 18px;
  margin-left: 145px;
  width: 106px;
  height: 34px;
  line-height: 34px;
  background: #029688;
  border-radius: 17px;
  color: white;
  text-align: center;
}

.vip_box {
  margin-top: 18px;
  margin-left: 9px;
  width: 81px;
  height: 34px;
  background: linear-gradient(0deg, #FF7A04, #FEB31F);
  border-radius: 17px;
  text-align: center;
  line-height: 34px;
}

.vip_box_icon {
  width: 20px;
  height: 18px;
}

.vip_box_text {
  width: 27px;
  height: 12px;
  font-size: 16px;
  margin-left: 7px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
}



.userinfo {
  display: flex;
  height: 70px;
  align-items: center;
}

.userinfo img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.username {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 30px;
}

.logout {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 30px;
  margin-left: 27px;
  cursor: pointer;
}


.pageChoose {
  height: 70px;
  display: flex;
  margin-left: 20px;
}

.chooseItem {
  margin-left: 50px;
  cursor: pointer;
}

.chooseItem p {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 70px;
}

.chooseColor {
  color: #029688 !important;
}

.message {
  /* height: 70px; */
  margin-left: 240px;
  margin-right: 60px;
  cursor: pointer;

}

.messageImg {
  width: 31px !important;
  height: 31px !important;
}
</style>
