import { createApp, VueElement } from 'vue'
import App from './App.vue'
// import Vue from 'vue'
import router from './router/index'
import ElementUI from 'element-plus';
import 'element-plus/dist/index.css'
import Layui from '@layui/layui-vue'
// import http from './api/axios.js'
// import '@layui/layui-vue/lib/index.css'
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'




// import Share from 'vue-social-share'


const app = createApp(App);
app.config.globalProperties.$getTime = function(item) {
    let date = new Date(parseInt(item) * 1000);
            let y = date.getFullYear();
            let m = date.getMonth() + 1;
            m = m < 10 ? "0" + m : m;
            let d = date.getDate();
            d = d < 10 ? "0" + d : d;
            let h = date.getHours();
            h = h < 10 ? "0" + h : h;
            let minute = date.getMinutes();
            let second = date.getSeconds();
            minute = minute < 10 ? "0" + minute : minute;
            second = second < 10 ? "0" + second : second;
            return (item = y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second);
}
// app.use(Share)
app.use(router)
app.use(ElementUI)
app.use(PerfectScrollbar)

app.use(Layui)
app.config.productionTip = false
app.mount('#app')

