import axios from 'axios'
//import { Message } from 'element-ui';  //element库的消息提示，可以不用

//创建axios实例
var service = axios.create({
    baseURL: 'https://www.aafdt.cn',
    // baseURL: 'https://www.aafdt.cn',
    timeout: 5000,
    headers: {
        'content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    }
})
//get请求
function get(url, data) {
    return service({
        url: url,
        method: 'get',
        data
    })
}
//post请求
function post(url, data){
    return service({
        url: url,
        method: "post",
        data: data
    })
}
export default {
    get,
    post
}