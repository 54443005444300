<template>
  <div class="header_nav">

    <div class="homepage_header_box" v-on:mouseenter="isShowMenu = true">
      <div class="margin_conter" v-if="isShowMenu">
        <div class="selectkind_box">
          <div class="layui-panel">
            <ul class="layui-menu" id="docDemoMenu1">
              <li class="layui-menu-item-parent parent_box" v-for="(item, index) in menulist" :key="index"
                lay-options="{type: 'parent'}">
                <div class="layui-menu-body-title" @click="clickDetails(item.id)">
                  {{ item.name }}
                  <i class="layui-icon layui-icon-right"></i>
                </div>
                <div class="layui-panel layui-menu-body-panel">
                  <ul>
                    <li lay-options="{id: 1051}" v-for="(el, elindex) in item.children" :key="elindex">
                      <div class="layui-menu-body-title">{{ el.name }}</div>
                    </li>

                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="header_nav_p">
      <div class="header_nav_box">
        <span class="header_nav_p_left">欢迎来到艾吖法数据</span>
        <span class="header_nav_p_right">服务热线：0371-0000000</span>
      </div>
    </div>
    <div class="header_content">
      <div class="header_content_box">
        <div class="logo">
          <img :src="'https://www.aafdt.cn'+ logo"  alt="">
        </div>
        <div>
          <div class="layui-input-block search_box">
            <input type="text" name="title" required v-model="sea" lay-verify="required" placeholder="请输入视频搜索"
              autocomplete="off" class="layui-input">
            <button type="button" class="layui-btn layui-btn_serach" @click="search">搜索</button>
          </div>
        </div>
        <div>
          <div class="fabu_box">
            <button type="button" @click="sub"
              class="layui-btn layui-btn_fabu layui-btn-primary layui-border-green">发布需求</button>
          </div>
        </div>
        <div>
          <div class="msg_box">
            <img src="../assets/img/msg.png" alt="" @click="gotack">
            <p>...</p>
          </div>
        </div>
      </div>
      <div class="nav_box">
        <div v-if="isMenu" v-on:mouseenter="mouseenterFun" v-on:mouseleave="isShowMenu = false">
          <div class="nav_kind">
            <span class="kind_icon"><img src="../assets/img/kindicon.png" alt=""></span>
            <span class="kind_text" v-on:mouseenter="isShowMenu = true">课堂分类</span>
          </div>




        </div>
        <div v-else>
          <div class="nav_kind">
            <span class="kind_icon"><img src="../assets/img/kindicon.png" alt=""></span>
            <span class="kind_text">课堂分类</span>
          </div>
        </div>
        <div>
          <div class="layui-tab layui-tab-brief router_box" lay-filter="docDemoTabBrief">
            <ul class="layui-tab-title title_position">
              <li @click="goPage(0)" class="layui-this">首页</li>
              <li @click="goPage(1)">艾吖法学堂</li>
              <li @click="goPage(2)">需求大厅</li>
              <li @click="goPage(6)">技术人员</li>
              <li @click="goPage(3)">讨论专区</li>
              <li @click="goPage(4)">关于我们</li>
              <li @click="goPage(5)">个人中心</li>
            </ul>
            <div class="layui-tab-content"></div>
          </div>
        </div>
        <div>
          <div class="login_box" v-if="isLogin == 0">
            <span @click="this.$router.push('/userLogin')">登陆</span>
            <span>｜</span>
            <span @click="this.$router.push('/userLogin')">注册</span>
          </div>
        </div>
        <div>
          <div class="vip_box">
            <span class="vip_box_icon"><img src="../assets/img/diamond.png" alt=""></span>
            <span class="vip_box_text" @click="this.$router.push('/openvip')">VIP</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/api/axios.js'
import { ElMessageBox, ElMessage } from 'element-plus'
export default {
  name: "headerLayout",
  watch: {
    $route: {
      handler(val, oldval) {
        this.isShowMenu = val.path == '/homePage'
        if (val.fullPath == "/homePage") {
          this.isMenu = false
        } else {
          this.isMenu = true
        }

      },
      // 深度观察监听
      deep: true
    }
  },
  data() {
    return {
      sea: '',
      isShowMenu: true,
      isMenu: false,
      isLogin: 0,
      menulist: [],
      logo: ''
    }
  },

   mounted() {
    this.img()
    http.post('/api/index/index', {

    }).then(res => {
      this.menulist = res.data.data.courseType
    })
    var token = sessionStorage.getItem("token")
    if (token == "" || token == undefined || token == "undefined" || token == null) {
      this.isLogin = 0
    } else {
      this.isLogin = 1
    }
    if (this.$route.path == "/homePage") {
      this.isShowMenu = true
    } else {
      this.isShowMenu = false
    }
  },
  methods: {
        gotack(){
            var token = sessionStorage.getItem("token")
      if (token == "" || token == undefined || token == "undefined" || token == null) {
        ElMessage.error('用户未登录')
        return
      }else{
       this.$router.push('jschat1')
      }
          
 

    },
    img() {
      http.post('api/index/websiteLogo').then(res => {
        if (res.data.code == 200) {
         this.logo = res.data.data
        }

      })
    },
    mouseenterFun() {
      this.isShowMenu = true
    },
    clickDetails(id) {
      this.$router.push("/xuetangPage?id=" + id)
    },
    goPage(index) {
      switch (index) {
        case 0:
          this.$router.push("/homePage")
          this.isShowMenu = true

          break;
        case 1:
          this.$router.push("/xuetangPage")
          this.isShowMenu = false

          break;
        case 2:
          this.$router.push("/xuqiuPage")
          this.isShowMenu = false

          break;
        case 3:
          this.$router.push("/discusspage")
          this.isShowMenu = false

          break;
        case 4:
          this.$router.push("/aboutwe")
          this.isShowMenu = false

          break;
        case 5:
          var type = sessionStorage.getItem("type")
          if (type == 1) {
            this.$router.push("/mycenter")
          } else if (type == 2) {
            this.$router.push("/myCenterArtisan")
          } else {
            this.$router.push("/userLogin")
          }


          this.isShowMenu = false

          break
        case 6:
          //跳转到技术人员列表
          this.$router.push("/peopleList")
          this.isShowMenu = false

          break;
        case 7:
          //跳转到技术人员列表
          this.$router.push("/userRelease")
          this.isShowMenu = false

          break;
      }
    },
    sub() {
      const is = sessionStorage.getItem('token')
      if (is == null) {
        this.$message.error('请先登陆')
      } else {
        const type = sessionStorage.getItem('type')
        if (type == 1) {
          this.$router.push("/userRelease")
        } else {
          this.$message.error('技术人员不能发布需求')
        }
      }

    },
    search() {
      const q = this.sea + ''
      this.$router.push({
        path: '/search',
        query: { q }
      })
    }
  }
}
</script>

<style scoped>
.selectkind_box {
  width: 238px;
  height: 525px;
  background: #FFFFFF;
  opacity: 0.9;
}

.layui-menu {
  border: none;
}

.layui-menu-item-parent {

  line-height: 44px;
  font-size: 16px;
}

.parent_box:hover {
  color: #029688;
}

.margin_conter {
  width: 1200px;
  height: 525px;
  margin: 0 auto;

}

.homepage_header_box {
  width: 100%;
  position: absolute;
  top: 215px;
  margin: 0 auto;
}

.layui-panel {
  /*height: 525px;*/
  box-shadow: none;
  border: none;
  z-index: 1000;
}

.header_nav {
  height: 220px;
  top: 0;
  z-index: 2;
  position: relative;
}

.layui-tab-title li {
  font-size: 18px;
}

.header_nav_p {
  height: 40px;
  background: #F1F3F8;
}

.header_nav_box {
  width: 1200px;
  height: 40px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header_nav_p_right {
  width: 175px;
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  line-height: 19px;
}

.header_nav_p_left {
  width: 129px;
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
  line-height: 19px;
}

.header_content {
  height: 180px;
  background: #FFFFFF;
}

.nav_box {
  width: 1200px;
  margin: 0 auto;
  padding-top: 30px;
  height: 56px;
  display: flex;
}

.header_content_box {
  margin: 0 auto;
  padding-top: 33px;
  width: 1200px;
  height: 60px;

  display: flex;

}

.header_content_box img {
  width: 211px;
  height: 59px;
}

.layui-input {
  height: 53px;
}

.layui-btn_serach {
  height: 53px;
  font-size: 16px;
  width: 106px;
  background: #029688;
}

.layui-btn_fabu {
  width: 174px;
  height: 53px;
  border: 2px solid #029688;
}

.fabu_box {
  margin-left: 34px;
}

.search_box {
  width: 474px;
  height: 53px;
  background: #F1F3F8;
  border: 1px solid #E1E1E1;
  display: flex;
  font-size: 16px;
}

.msg_box {
  margin-left: 133px;
  margin-top: 12px;
  position: relative;
}

.msg_box img {
  width: 31px;
  height: 31px;
}

.msg_box p {
  position: absolute;
  width: 19px;
  height: 19px;
  background-color: red;
  border-radius: 50%;
  text-align: center;
  color: white;
  right: -7px;
  top: -7px;
}

.nav_kind {
  width: 238px;
  height: 56px;
  line-height: 56px;
  background: #029688;
  border-radius: 20px 20px 0px 0px;
  text-align: center;
  cursor: pointer;

}

.kind_icon img {
  width: 20px;
  height: 19px;
  margin-bottom: 5px;
}

.kind_text {
  margin-left: 9px;
  width: 73px;
  height: 17px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #F1F3F8;
  line-height: 19px;
}

.router_box {
  margin-left: 42px;
}

.title_position {
  margin-top: 15px;
}

.login_box {
  margin-top: 18px;
  width: 106px;
  height: 34px;
  line-height: 34px;
  background: #029688;
  border-radius: 17px;
  color: white;
  text-align: center;
}

.vip_box {
  margin-top: 18px;
  margin-left: 9px;
  width: 81px;
  height: 34px;
  background: linear-gradient(0deg, #FF7A04, #FEB31F);
  border-radius: 17px;
  text-align: center;
  line-height: 34px;
}

.vip_box_icon {
  width: 20px;
  height: 18px;
}

.vip_box_text {
  width: 27px;
  height: 12px;
  font-size: 16px;
  margin-left: 7px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
}
</style>
