<template>
  <div class="footer">
    <div class="footer_box">
      <div class="footer_top">
        <div>
          <p class="shouhou">售后客服</p>
          <p class="phone">{{V0}}</p>
          <p class="worktime">{{V1}}</p>
          <p class="zixun">
            <img src="../assets/img/zixun.png" alt="">
            <span><a target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=287751961&site=qq&menu=yes">在线咨询</a></span>
          </p>
        </div>
        <div>
          <p class="shouhou">底部导航</p>
          <p class="relation">首页 艾吖发学堂 需求展示 讨论专区 关于我们 </p>
          <p class="lianxi">联系我们</p>
          <p class="gongsi">公司:{{V2}}</p>
          <p class="address">地址:{{V3}}</p>
          <p class="email">邮箱: {{V4}}</p>
        </div>
        <div class="qq_box">
          <p class="code_box"><img src="../assets/img/code.png" alt=""></p>
          <p class="saomiao">扫描下载APP</p>
          <p class="jiaoliuqun">艾吖法数据交流群1: <a target="_blank" href="https://qm.qq.com/cgi-bin/qm/qr?k=1BshNdq9KK18YH1FIx30JpSQ8Aky2f4K&jump_from=webapi"><img border="0" src="//pub.idqqimg.com/wpa/images/group.png" alt="艾吖法数据分析交流群" title="艾吖法数据分析交流群"></a ></p>
          <!-- <p class="jiaoliuqun">艾吖法数据交流群2: <a target="_blank" href="https://qm.qq.com/cgi-bin/qm/qr?k=9H8uby09G4uPBwRkrVN5Nqc5DpaahWXP&jump_from=webapi"><img border="0" src="//pub.idqqimg.com/wpa/images/group.png" alt="4" title="4"></a></p> -->
        </div>    
      </div>

    </div>
    <hr style="margin-top: 73px">
    <div class="footer_bottom">
      <p class="lianjie">友情链接: <router-link  v-for="item in link" :to="item.url" class="lianjie">{{item.title}}</router-link></p>
      <p class="company">Copyright © 2022 昆明市呈贡艾吖法数据处理工作室 备案号:滇ICP备13001775号-2</p>
    </div>
  </div>
</template>

<script>
import http from "@/api/axios.js";
export default {
  data() {
    return {
     link: [],
     V0:"",
     V1:"",
     V2:"",
     V3:"",
     V4:"",
     V5:"",
     V6:"",
     V7:"",
    }
  },
  methods: {
   getlink(){
    http.post('api/index/chain').then(res => {
       this.link = res.data.data
    })
    var that = this
    http.post('api/index/content').then(res => {
        this.V0 = res.data.data[0].value
        this.V1 = res.data.data[1].value
        this.V2 = res.data.data[2].value
        this.V3 = res.data.data[3].value
        this.V4 = res.data.data[4].value
        this.V5 = res.data.data[5].value
        this.V6 = res.data.data[6].value
        this.V7 = res.data.data[7].value
    })
   }
  },
  created() {
   this.getlink()
  }
}
</script>

<style scoped>
.footer {
  height: 548px;
  color: white;
  background: #07111B;
  margin-top: 50px;
  padding-top: 10px;

}

.footer_box {
  width: 1200px;
  margin: 0px auto;
}

.footer_top {
  margin-top: 70px;
  display: flex;
  justify-content: space-between;

}

.shouhou {
  width: 56px;
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
}

.phone {
  width: 220px;
  height: 22px;
  font-size: 28px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #029688;
  margin-top: 28px;
}

.worktime {
  width: 145px;
  height: 13px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  margin-top: 19px;

  color: #FFFFFF;
}

.zixun {
  width: 156px;
  height: 36px;
  border: 1px solid #FAFAFA;
  border-radius: 18px;
  margin-top: 49px;
  text-align: center;
  line-height: 36px;
}

.zixun p {
  width: 18px;
  height: 18px;
}

.zixun span {
  margin-left: 10px;
}

.relation {
  margin-top: 29px;
  width: 410px;
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #CCCCCC;
}

.lianxi {
  margin-top: 33px;
  width: 60px;
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
}

.gongsi {
  margin-top: 26px;
  width: 248px;
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #CCCCCC;
}

.address {
  margin-top: 19px;
  width: 434px;
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #CCCCCC;
}

.email {
  margin-top: 21px;
  width: 146px;
  height: 15px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #CCCCCC;
}

.code_box {
  width: 146px;
  height: 146px;
}

.code_box img {
  margin-left: 70%
}

.qq_box {

  text-align: center;
  width: 30%;
}

.jiaoliuqun {
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #CCCCCC;
  text-align: center;
}

.jiaoliuqun img {
  width: 90px;
  height: 22px;
}

.lianjie {
  width: 1190px;
  height: 45px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  margin-right: 15px;
}

.company {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}

.footer_bottom {
  width: 1200px;
  margin: 0 auto;
  text-align: center;
}
</style>
