import {createRouter, createWebHashHistory} from 'vue-router'
const WebHashHistory = createWebHashHistory()
export default createRouter({
    history: WebHashHistory,
    routes: [
        {
            path: '/',   // 跳转路径
            name: 'app',    // 名称
            redirect:"/homePage"
        },
        {
            path: '/homePage',   // 跳转路径
            name: 'homePage',    // 名称
            component:()=>import("../pages/homePage/homePage")
        },
        {
            path: '/xuetangPage',   // 跳转路径
            name: 'xuetangPage',    // 名称
            component:()=>import("../pages/xuetangPage/index")
        },
        {
            path: '/discussPage',   // 跳转路径
            name: 'discussPage',    // 名称
            component:()=>import("../pages/discussPage/index")
        },
        {
            path: '/subPage',   // 跳转路径
            name: 'subPage',    // 名称
            component:()=>import("../pages/discussPage/subPage")
        },
        {
            path: '/detaillsPage',   // 跳转路径
            name: 'detaillsPage',    // 名称
            component:()=>import("../pages/discussPage/detaillsPage")
        },
        {
            path: '/EventAppointment',   // 跳转路径
            name: 'EventAppointment',    // 名称
            component:()=>import("../pages/homePage/EventAppointment")
        },
        {
            path: '/details',   // 跳转路径
            name: 'details',    // 名称
            component:()=>import("../pages/homePage/details")
        },
        {
            path: '/ordinaryPage',   // 跳转路径
            name: 'ordinaryPage',    // 名称
            component:()=>import("../pages/xuetangPage/ordinaryPage")
        },
        {
            path: '/xilieVideoPage',   // 跳转路径
            name: 'xilieVideoPage',    // 名称
            component:()=>import("../pages/xuetangPage/xilieVideoPage")
        },
        {
            path: '/xuqiuPage',   // 跳转路径
            name: 'xuqiuPage',    // 名称
            component:()=>import("../pages/xuqiuPage/xuqiuPage")
        },
        {
            path: '/DetailPage',   // 跳转路径
            name: 'DetailPage',    // 名称
            component:()=>import("../pages/xuqiuPage/DetailPage")
        },
        {
            path: '/releaseDemand',   // 跳转路径
            name: 'releaseDemand',    // 名称
            component:()=>import("../pages/xuqiuPage/releaseDemand")
        },
        {
            path: '/rankPage',   // 跳转路径
            name: 'rankPage',    // 名称
            component:()=>import("../pages/xuqiuPage/rankPage")
        },
        {
            path: '/mycenter',   // 跳转路径
            name: 'mycenter',    // 名称
            component:()=>import("../pages/myCenter/myCenter")
        },
        {
            path: '/myCenterArtisan',   // 跳转路径
            name: 'myCenterArtisan',    // 名称
            component:()=>import("../pages/myCenterArtisan/myCenter")
        },
        {
            path: '/openvip',   // 跳转路径
            name: 'openvip',    // 名称
            component:()=>import("../pages/vip/openvip")
        },
        {
            path: '/comment',   // 跳转路径
            name: 'comment',    // 名称
            component:()=>import("../pages/comment/index")
        },
        {
            path: '/demandhall',   // 跳转路径
            name: 'demandhall',    // 名称
            component:()=>import("../pages/demandHall/index")
        },
        {
            path: '/demanddetial',   // 跳转路径
            name: 'demanddetial',    // 名称
            component:()=>import("../pages/demandHall/demandDetail")
        },
        {
            path: '/uploadVideo',   // 跳转路径
            name: 'uploadVideo',    // 名称
            component:()=>import("../pages/uploadVideo/index")
        },
        //系列视频列表
        { 
            path: '/Seriesvideo',   // 跳转路径
            name: 'Seriesvideo',    // 名称
            component:()=>import("../pages/uploadVideo/Seriesvideo")
        },
        {
            path: '/Postvideo',   // 跳转路径
            name: 'Postvideo',    // 名称
            component:()=>import("../pages/uploadVideo/Postvideo")
        },
        {
            path: '/Addseriesvideo',   // 跳转路径
            name: 'Addseriesvideo',    // 名称
            component:()=>import("../pages/uploadVideo/Addseriesvideo")
        },
        {
            path: '/catalogue',   // 跳转路径
            name: 'catalogue',    // 名称
            component:()=>import("../pages/uploadVideo/catalogue")
        },
        {
            path: '/artisanEditInfo',   // 跳转路径
            name: 'artisanEditInfo',    // 名称
            component:()=>import("../pages/artisanEditInfo/index")
        },
        // 发布需求
        {
            path: '/userRelease',   // 跳转路径
            name: 'userRelease',    // 名称
            component:()=>import("../pages/userRelease/index")
        },
        // 用户登录
        {
            path: '/userLogin',   // 跳转路径
            name: 'userLogin',    // 名称
            component:()=>import("../pages/userLogin/login")
        },
        // 技术人员登录
        {
            path: '/artisanLogin',   // 跳转路径
            name: 'artisanLogin',    // 名称
            component:()=>import("../pages/artisanLogin/login")
        },
// 技术人员登录
        {
            path: '/peopleList',   // 跳转路径
            name: 'peopleList',    // 名称
            component:()=>import("../pages/xuqiuPage/peopleList")
        },
        {
            path: '/peopleDetaill',   // 跳转路径
            name: 'peopleDetaill',    // 名称
            component:()=>import("../pages/xuqiuPage/peopleDetaill")
        },
		{
		    path: '/jsChat',   // 跳转路径
		    name: 'jsChat',    // 名称
		    component:()=>import("../pages/chat/jsChat")
		},
		{
		    path: '/jsChat1',   // 跳转路径
		    name: 'jsChat1',    // 名称
		    component:()=>import("../pages/chat/jsChat1")
		},
		{
		    path: '/search',   // 跳转路径
		    name: 'search',    // 名称
		    component:()=>import("../pages/search/index")
		},
        {
		    path: '/aboutwe',   // 跳转路径
		    name: 'aboutwe',    // 名称
		    component:()=>import("../pages/aboutwe/aboutwe")
		},
    ]
    /*
     *   在这里和vue2一样写路由配置
    */
})

